.post-page {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 2rem;
}

.post-details {
	display: flex;
	flex-direction: column;
	max-width: 1000px;
	/* gap: 1rem; */
}

.post__content {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	max-width: 1000px;
	padding: 1rem;
}

.post__image {
	width: 100%;
	border-radius: 24px;
}

.post__title {
	font-size: 2rem;
}

.post__tags {
	display: flex;
	flex-wrap: wrap;
	gap: 0.5rem;
}

.post__links {
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
	height: 35px;
}

.post__repo-icon {
	height: 35px;
}

.post__top-row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}