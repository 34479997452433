header {
	position: sticky;
	top: 0;
}

.header {
	position: sticky;
	top: 10px;
	display: flex;
	padding: 0.75rem;
	justify-content: center;
	background-color: var(--background-color);
}

body.dark .header {
	background-color: var(--background-color-dark);
}

.header__links-container {
	display: flex;
	justify-content: space-between;
	width: 100%;
	max-width: 1200px;
}

.header__links-container {
	display: flex;
	align-items: center;
	font-size: 17px;
}

.header__left-side {
	display: flex;
	align-items: center;
	gap: 2rem;
}

.header__logo {
	font-size: 1.5rem;
}