.main__links {
	display: flex;
	justify-content: space-evenly;
	/* margin-top: 25px; */
	gap: 3rem;
}

.main__links img {
	height: 48px;
	transition: ease-in-out 150ms;
}

.main__links img:hover {
	transform: translate(0, -5px);
	transition: ease-in-out 200ms;
}

@media (max-width: 1000px) {
	/* .main__links {
		width: 100%;
	} */

	.main__links img {
		height: 32px;
	}
}
