.project-list {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.project-list__grid {
	display: grid;
	align-items: center;
	grid-template-columns: repeat(2, 1fr);
	gap: 2rem;
	margin: 1rem;
	max-width: 1200px;
}

.project__preview {
	display: flex;
	background-color: var(--background-color);
	border-radius: 24px;
	flex-direction: column;
	justify-content: center;
	background-color: rgb(243, 243, 243);
	box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
		rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
		rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
	transition: 0.2s ease-in-out;
	/* max-height: 400px; */
}

.project__preview:hover {
	transform: scale(1.02);
	text-decoration: none;
}

.project__preview img {
	width: 100%;
	border-radius: 24px 24px 0 0;
	/* border-bottom: 1px solid black; */
}

.project__preview-text {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	padding: 0.5rem 1rem 1rem 1rem;
}

.project__bottom-row {
	display: flex;
	flex-wrap: wrap;
	gap: 0.5rem;
	align-items: center;
}

.project__tag {
	/* border: 2px solid black; */
	border-radius: 24px;
	padding: 0.4rem;
	background-color: white;
	box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

@media (max-width: 1000px) {
	.project-list__grid {
		grid-template-columns: 1fr;
	}
}
