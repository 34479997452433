:root {
	/* Fonts */
	--primary-font: 'Source Code Pro', monospace;
	
	/* Font weights */
	--normal-weight: 400;
	--medium-weight: 500;
	--bold-weight: 600;

	/* Colours */
	--background-color: #cfeeee;
	--background-color-dark: #2a2a3b;

	--card-color: #2b2b2b;

	--primary-font-color: #3f3f3f;
	--primary-font-color-dark: #e25d42;
	
	--secondary-font-color: #d0cae5;

	--orange: #F95A00;
	--darker-orange: #fa7613;
	--peach: #ffce6f;
	--yellow: #FDF275;
	--red: #ED2222;
	--lighter-red: #f34949;
	--pink: #FD2170;
	--light-pink: #fdc1e9;
	--lime: #C2F470;
	--dark-green: #008162;
	--softer-dark-green: #019767;
}