html {
	scroll-behavior: smooth;
}

body {
	font-family: var(--primary-font);
	color: var(--primary-font-color);
	background-color: var(--background-color);
}

body.dark {
	color: var(--primary-font-color-dark);
	background-color: var(--background-color-dark);
}

/* @media (prefers-color-scheme: dark) {
  body {
	 color: var(--primary-font-color-dark);
	 background-color: var(--background-color-dark);
  }
} */

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

a {
	text-decoration: none;
}

a:visited {
	text-decoration: none;
	color: inherit;
}

a:hover {
	text-decoration: underline;
}

li {
	list-style: none;
}

img {
	width: 100%;
}

button {
	font: inherit;
	color: inherit;
	background: transparent;
	border: transparent;
	cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.main-block {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	gap: 2rem;
}

.main__title {
	font-size: 3rem;
}

.primary-button {
	background-color: white;
	width: fit-content;
	border-radius: 16px;
	padding: 0.5rem;
	color: black;
	height: 35px;
}

a:-webkit-any-link {
	color: unset;
}

.is-loading {
	position: absolute;
	top: 40%;
	height: 100px;
	width: 100px;
}

.is-loading-spinner {
	height: 100%;
	width: 100%;
}

.is-loading svg path {
	color: #e25d42;
}

.not-found {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 80vh;
	gap: 1rem;
}

@media (max-width: 1000px) {
	.main__title {
		font-size: 7vw;
	}
}
